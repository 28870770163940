<template>
  <span>
    <span class="ag-value-change-delta" :class="classObject" v-if="params.valueFormatted">
      <span v-if="+params.value !== 0">{{ arrow }}</span> {{ params.valueFormatted }}
    </span>
  </span>
</template>

<script>
  export default {
    computed: {
      arrow() {
        let up = this.params.context.forward;
        if (up) {
          if (this.params.value && this.params.value < 0) up = false;
        } else {
          if (this.params.value && this.params.value < 0) up = true;
        }
        return (up) ? '↑' : '↓'
      },
      classObject() {
        return {
          'ag-value-change-delta-down': this.params.value < 0,
          'ag-value-change-delta-up':  this.params.value > 0
        }
      }
    },
  }
</script>
