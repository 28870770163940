import Big from 'big.js';

import NameCell from '../components/cells/NameCell'
import InputTypeCell from '../components/cells/InputTypeCell'
import BidCell from '../components/cells/BidCell'
import ButtonCell from '../components/cells/ButtonCell'
import DeltaCell from '../components/cells/DeltaCell'
import LeadBid from '../components/cells/LeadBid'
import WeightedCell from '../components/cells/WeightedCell'

import LoadingOverlay from '../components/cells/LoadingOverlay'
import CustomHeader from '../components/cells/CustomHeader'

// Allow 0 as that's an acceptable bid, but in JS it's falsy
export function notBlank(val) {
  return val || val === 0
}

export function isBlank(val) {
  return !notBlank(val);
}

export function numberParser(params) {
  let { newValue, context } = params;

  if (typeof newValue === 'string') {
    if (newValue.length === 0) return null;
    const parsed = new NumberParser(context).parse(newValue);
    if (!_.isNaN(parsed)) return parsed;
  }
  return newValue;
}

export class NumberParser {
  constructor(context) {
    const numerals = Array.from(new Intl.NumberFormat(context.locale, {useGrouping: false}).format(9876543210)).reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._currency = context.symbol;
    this._dollar = context.symbol.length > 1 && context.symbol[context.symbol.length -1] === '$'; // Still want to strip dollar symbol even when currency has prepended chars
    this._group =  new Intl.NumberFormat(context.locale).format(1111).replace(/1/g, '');
    this._decimal = new Intl.NumberFormat(context.locale).format(1.1).replace(/1/g, '');
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d);
  }
  parse(string) {
    let parsed = string.trim();
    if (this._dollar) {
      parsed = parsed.replace('$', "")
    }
    parsed = (parsed
      .replace(this._currency, "")
      .replace(/\((?=\d+)(.*)\)/, "-$1") // replace bracketed values with negatives; TODO: localize?
      .replace(/\%$/, "") // remove ending percent sign; TODO: localize?
      .replace(new RegExp('\\' + this._group, 'g'), "")
      .replace(new RegExp('\\' + this._decimal, 'g'), ".")
      .replace(this._numeral, this._index));

    return parsed ? +parsed : NaN;
  }
}


// ----------------------------------------------------------------------
// Column Definitions
// ----------------------------------------------------------------------

const rankCol = {
  field: 'rank',
  width: 48,
  resizable: false,
  valueFormatter: (params) => (params.value || 'N/A'),
  cellClass: 'ag-rank-cell',
  cellClassRules: {
    winning: (params) => params.value === 1,
    losing: (params) => params.value > 1
  },
  cellRenderer: (params) => `<span class="rank">${params.valueFormatted}</span>`,
}

const numberCol = {
  field: 'number',
  headerName: '#',
  width: 72
}

const nameColFooter = () => '<strong>Total</strong>';
const nameCol = {
  field: 'name',
  headerName: 'Item Name',
  flex: 1,
  minWidth: 210,
  cellRendererSelector: params => {
    return {
      component: (params.node.rowPinned) ? nameColFooter : "NameCell"
    }
  },
  tooltipValueGetter: params => (params.node.rowPinned ? null : params.data.name)
}

const qtyColFooter = () => '';
const qtyCol = {
  field: 'qty',
  headerName: 'Qty',
  width: 72,
  cellRendererSelector: params => {
    return {
      component: (params.node.rowPinned) ? qtyColFooter : undefined
    }
  }
}

const leadBidCol = {
  field: 'lead',
  type: 'moneyType',
  headerName: 'Lead Bid',
  hide: true,
  cellRenderer: "LeadBid",
  valueGetter: (params) => {
    const lead = params.data.lead,
          leading = notBlank(lead) && params.data.rank === 1; // hide if no lead bid present
    return { lead, leading }
  },
  valueFormatter: params => formatByInputType({value: params.value.lead, data: params.data, context: params.context}),
  equals: (oldValue, newValue) => (oldValue.lead === newValue.lead && oldValue.leading === newValue.leading),
}

const reserveCol = {
  field: 'reserve',
  type: 'moneyType',
  hide: true,
}

const openingCol = {
  field: 'opening',
  type: 'buttonType',
  width: 120,
  hide: true,
  cellRendererParams: {
    errorMsg: params => 'Opening Not Met',
    errorPlacement: 'left'
  },
  valueGetter: getButtonValue
}

const nextCol = {
  field: 'next',
  type: 'buttonType',
  width: 120,
  headerName: 'Next Bid',
  hide: true,
  cellRendererParams: {
    errorMsg: params => 'Next Bid Not Met',
    errorPlacement: 'left'
  },
  valueGetter: getButtonValue
}

const inputTypeColFooter = params => params.context.symbol;
const inputTypeCol = {
  colId: 'inputType',
  headerName: 'Input',
  resizable: false,
  width: 36,
  cellClass: ['ag-input-cell', 'ag-input-type'],
  cellRendererSelector: params => {
    return {
      component: (params.node.rowPinned) ? inputTypeColFooter : "InputTypeCell"
    }
  },
  valueGetter: params => {
    const { data } = params;
    const inputType = data.input_type;
    if (inputType !== 'markup') return {inputType};

    return {
      inputType,
      markup: data.markup,
      placed: params.getValue('placed'),
      bid: params.getValue('entered')
    }
  },
  equals: (oldVal, newVal) => (JSON.stringify(oldVal) === JSON.stringify(newVal))
}

const bidCol = {
  field: 'entered',
  headerName: 'Your Bid',
  type: 'moneyType',
  cellClass: ['ag-right-aligned-cell', 'ag-input-cell'],
  cellClassRules: {
    'ag-money-cell': params => (params.data.input_type === 'price' || params.node.rowPinned),
    'ag-percent-cell': params => (params.data.input_type !== 'price' && !params.node.rowPinned)
  },
  cellEditorParams: { useFormatter: true },
  editable: (params) => !params.node.rowPinned,
  valueFormatter: formatDecimal,
  valueSetter: setBid
}

const newBidCol = {
  field: 'bid',
  headerValueGetter: params => ((params.context.bidState.enteredBids) ? 'New Bid' : 'Enter Bid'),
  type: 'moneyType',
  cellClass: ['ag-right-aligned-cell', 'ag-input-cell'],
  cellClassRules: {
    'ag-money-cell': params => (params.data.input_type === 'price' || params.node.rowPinned),
    'ag-percent-cell': params => (params.data.input_type !== 'price' && !params.node.rowPinned),
    'cell-no-change': noChange
  },
  editable: (params) => !params.node.rowPinned,
  minWidth: 120, // Keep it a resonable size for autoSize
  cellRenderer: 'BidCell',
  cellRendererParams: {
    clicked: clearBid
  },
  cellEditorParams: { useFormatter: true },
  valueFormatter: formatDecimal,
  valueSetter: setBid
}

const placedCol = {
  colId: 'placed',
  hide: true,
  valueGetter: calculatePlaced
}

const deltaCol = {
  colId: 'delta',
  headerName: 'Change',
  type: 'moneyType',
  cellClass: ['ag-right-aligned-cell', 'ag-input-cell'],
  width: 101,
  editable: (params) => (!params.node.rowPinned),
  cellRenderer: "DeltaCell",
  valueGetter: (params) => calculateDeltaFor(params, 'bid', 'entered'),
  valueFormatter: params => formatDecimal({value: Math.abs(params.value), data: params.data, context: params.context}),
  valueSetter: applyDelta
}

const lastDeltaCol = {
  colId: 'lastDelta',
  field: 'delta',
  width: 59,
  type: 'buttonType',
  valueGetter: btnCellValueGetter,
  headerValueGetter: params => ((params.context.forward) ? 'Last ↑' : 'Last ↓'),
  headerComponentParams: {
    tooltip: params => ((params.context.forward) ? 'Last Raise' : 'Last Reduction')
  },
  cellRendererParams: {
    errorMsg: () => ''
  }
}

const decrementCol = {
  field: 'decrement',
  width: 55,
  type: 'buttonType',
  valueGetter: btnCellValueGetter,
  headerValueGetter: params => ((params.context.forward) ?  'Min ↑' : 'Min ↓'),
  headerComponentParams: {
    tooltip: params => ((params.context.forward) ? 'Minimum Raise' : 'Minimum Reduction')
  },
  cellRendererParams: {
    errorMsg: params => `Min ${(params.context.forward) ? 'Raise' : 'Reduction'} Not Met`
  },
}

const markupCol = {
  field: 'markup',
  hide: true,
  width: 120,
  cellClass: 'ag-money-cell',
  valueFormatter: formatCurrency,
}

const percentageCol = {
  field: 'percentage',
  headerName: 'Weight',
  hide: true,
  width: 80,
  cellClass: 'ag-percent-cell',
  valueFormatter: formatPercent
}

const adjustmentCol = {
  field: 'adjustment',
  hide: true,
  width: 120,
  cellClass: 'ag-money-cell'
}

const weightedBidCol = {
  colId: 'weightedBid',
  headerName: 'Weighted Bid',
  hide: true,
  width: 120,
  cellClass: 'ag-money-cell',
  valueGetter: calculateWeight
}

const formulaColFooter = () => '';
const formulaCol = {
  colId: 'weighted',
  headerComponent: "WeightHeader",
  cellClass: 'ag-input-cell',
  hide: true,
  resizable: false,
  width: 30,
  cellRendererSelector: params => {
    return {
      component: (params.node.rowPinned) ? formulaColFooter : "WeightedCell"
    }
  },
  valueGetter: calculateWeight,
}

const extQtyCol = {
  colId: 'extQty',
  headerName: 'Ext Qty',
  field: 'ext_qty',
  type: 'numericColumn',
  width: 65,
  valueFormatter: formatInteger
}

const extBidCol = {
  headerName: 'Ext Bid',
  colId: 'extBid',
  type: 'moneyType',
  valueGetter: params => stagedExtValue(params, 'placed', 'extEntered'),
  cellClassRules: {
    'cell-change': isStaged
  }
}

const extDeltaCol = {
  colId: 'extDelta',
  headerValueGetter: params => ((params.context.forward) ? 'Ext Bid ↑' : 'Ext Bid ↓'),
  headerComponentParams: {
    tooltip: () => 'Extended Bid Change'
  },
  type: 'moneyType',
  width: 96,
  cellRenderer: "DeltaCell",
  valueGetter: params => calculateDeltaFor(params, 'extBid', 'ext_entered'),
  valueFormatter: params => formatDecimal({value: Math.abs(params.value), data: params.data, context: params.context}),
}

const weightedExtBidCol = {
  colId: 'extWeighted',
  headerName: 'Weighted Bid',
  type: 'moneyType',
  hide: true,
  valueGetter: params => stagedExtValue(params, 'weighted', 'weighted'),
  cellClassRules: {
    'cell-change': isStaged
  }
}

// Forece asc sort on hidden column
const sort = {
  field: 'sort',
  hide: true,
  sort: 'asc'
}


// ----------------------------------------------------------------------
// Value Setters
// ----------------------------------------------------------------------

function setBid(params) {
  const { newValue, oldValue, data } = params;
  if (newValue !== oldValue) {
    // If has value and not equal to entered, but always keep if in bid col
    if (notBlank(newValue) && (isNaN(newValue) || isBlank(data.entered) || params.column.colId === 'bid' || !Big(newValue).eq(data.entered))) {
      data.bid = newValue;
    } else {
      delete data.bid;
    }
    delete data.errors; // Reset errors
    return true;
  }
  return false
}

function applyDelta(params) {
  let { newValue, context, data } = params;

  // newValue is already parsed into a number
  if (notBlank(newValue) && !isNaN(newValue)) {
    if (newValue === 0) {
      delete data.bid; // entering 0 change effectively clears the bid
    } else {
      if (notBlank(data.entered)) {
        newValue = Big(data.entered)[(context.forward) ? 'plus' : 'minus'](newValue)
      }
      params.node.data.bid = newValue;
    }
  }

  return true
}


function updateNode(params, node, colId) {
  console.log('node', node, params)
  let data = node.data;

  if (colId === undefined) {
    delete data.bid; // Clear bid
  } else {
    console.log(params)
    const value = params.api.getValue(colId, node);
    data.bid = value.newBid;
  }
  delete data.errors; // Reset errors
  return data;
}


// ----------------------------------------------------------------------
// Value Getters
// ----------------------------------------------------------------------

export function getBidValue(data) {
  return (notBlank(data.bid) ? data.bid : data.entered)
}

function getButtonValue(params) {
  const { data, context } = params;
  const { bid, entered } = data;

  let amt = data[params.colDef.field],
      error = false,
      newBid = amt;

  if (notBlank(amt) && notBlank(bid) && (isBlank(entered) || bid !== entered)) {
    error = Big(bid)[(context.forward) ? 'lt' : 'gt'](newBid);
  }

  return {
    amt,
    newBid,
    error
  }
}

function calculatePlaced(params) {
  const data = params.data,
        bid = params.data.bid;

  // If there was a parse error return current bid
  if (typeof bid === 'string' || !isStaged(params)) return notBlank(data.placed) ? data.placed : data.entered;

  if (notBlank(bid) && data.input_type === 'markup') {
    if (data.markup) {
      return Number(Big(data.markup).times( Big(bid).times(0.01).plus(1) ).round(data.precision));
    } else {
      return null; // Markup required
    }
  }

  return bid;
}

function calculateWeight(params) {
  const data = params.data,
        bid = params.getValue('placed');

  if (notBlank(bid)) {
    if (data.markup || data.percentage || data.adjustment) {
      let weighted = Big(bid); // Used for calculations

      // Percentage input cannot be weighted
      if (data.input_type !== 'percentage') {
        if (data.percentage) {
          weighted = weighted.times(data.percentage).round(data.precision);
        }

        if (data.adjustment) {
          weighted = weighted.plus(data.adjustment);
        }
      }

      if (!weighted.eq(bid)) {
        return weighted.toString();
      }
    }
  }

  return bid
}

function stagedExtValue(params, colId, attribute) {
  // Pinned footer aggregate value
  if (params.node.rowPinned) return params.data[(colId === 'placed') ? 'extBid' : 'weighted']

  const value = params.getValue(colId);
  let extQty = params.data.ext_qty;

  // Weighted value should display even when extQty === 0
  if (colId === 'weighted' && params.data.input_type !== 'percentage' && !extQty) extQty = 1;

  if (notBlank(value) && extQty) {
    return Number(Big(value).times(extQty));
  }

  return params[attribute]
}

function calculateDeltaFor(params, newField, oldField) {
  if (!isStaged(params)) return null;

  // NOTE: newVal requires api val
  const newVal = params.getValue(newField),
        oldVal = params.data[oldField];

  // Make sure to check if was parsed correctly
  if (typeof newVal !== 'string' && notBlank(newVal) && notBlank(oldVal)) {
    if (!Big(newVal).eq(oldVal)) {
      const delta = Number(Big(oldVal).minus(newVal));
      return (params.context.forward) ? -delta : delta
    }
  }

  // Return 0 if empty
  return 0;
}

function isStaged(params) {
  const value = params.api.getValue('bid', params.node);
  return notBlank(value) && value !== params.data.entered
}

// Subtly different from isStaged, in that it wants a value, but unchanged
function noChange(params) {
  const value = params.api.getValue('bid', params.node);
  return notBlank(value) && value === params.data.entered
}

// ----------------------------------------------------------------------
// Value Formatters
// ----------------------------------------------------------------------
// TODO: consider caching formatters (requires one per precision)

export function formatCurrency(params, precision) {
  const { value, data, context } = params;

  if (value === undefined || value === null) return null;
  if (precision === undefined || precision === null) precision = data.precision;

  return new Intl.NumberFormat(context.locale, {
    currency: context.currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: 'currency',
    useGrouping: true
  }).format(value)
}

function formatDecimal(params) {
  const { value, data, context } = params;

  if (value === undefined || value === null || typeof value === 'string') return value;

  return new Intl.NumberFormat(context.locale, {
    minimumFractionDigits: data.precision,
    maximumFractionDigits: data.precision,
    useGrouping: true
  }).format(value)
}

export function formatPercent(params, precision) {
  const { value, data, context } = params;

  if (value === undefined || value === null) return value;

  if (precision === undefined) precision = data.precision;

  return new Intl.NumberFormat(context.locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: true,
    style: 'percent'
  }).format(value)
}

function formatInteger(params) {
  const { value, context } = params;

  if (value === undefined || value === null) return null;

  return new Intl.NumberFormat(context.locale, {
    maximumFractionDigits: 0
  }).format(value)
}

function formatByInputType(params) {
  return (params.data.input_type === 'percentage') ? formatPercent(params) : formatCurrency(params)
}


// ----------------------------------------------------------------------
// Column Types
// ----------------------------------------------------------------------

const moneyType = {
  cellClass: ['ag-right-aligned-cell', 'ag-money-cell'],
  headerClass: 'ag-right-aligned-header',
  width: 120,
  valueFormatter: formatCurrency,
  valueParser: numberParser
}

const buttonTypeFooter = () => '';
const buttonType = {
  cellClass: ['ag-right-aligned-cell', 'ag-input-cell'],
  headerClass: 'ag-right-aligned-header',
  valueFormatter: params => formatDecimal({value: params.value.amt, data: params.data, context: params.context}),
  equals: (oldValue, newValue) => (oldValue.amt === newValue.amt && oldValue.newBid === newValue.newBid && oldValue.error === newValue.error),
  cellRendererSelector: params => {
    return {
      component: (params.node.rowPinned) ? buttonTypeFooter : "ButtonCell"
    }
  }
}

function clearBid(params, event) {
  event.preventDefault();
  if (params.node.rowPinned) {
    let itemsToUpdate = [];
    params.api.forEachNode(node => {
      const data = updateNode(params, node);
      itemsToUpdate.push(data);
    });
    params.api.applyTransaction({ update: itemsToUpdate })
  } else {
    params.node.setDataValue('bid', null)
  }
}

function btnCellValueGetter(params) {
  const { data, context } = params;
  const bid = data.bid,
        entered = data.entered;

  let amt = data[params.colDef.field],
      error = false,
      newBid;

  // Only show decrement when bid has been placed (e.g. non-required items)
  if (params.colDef.field === 'decrement' && isBlank(entered)) amt = null;

  if (notBlank(amt) && notBlank(entered)) {
    newBid = Big(entered)[(context.forward) ? 'plus' : 'minus'](amt);
    // If decrement column, show error if out of bounds
    if (params.colDef.field === 'decrement' && notBlank(bid) && !isNaN(bid) && bid !== entered) {
      error = Big(bid)[(context.forward) ? 'lt' : 'gt'](newBid);
    }
  }

  return {
    amt,
    newBid,
    error
  }
}


// ----------------------------------------------------------------------
// Quick Cell Renderers
// ----------------------------------------------------------------------

const WeightHeader = {
  template: `<tooltip text="Weighting Formula" custom-class="tooltip-bold" :enterable="false" :show-delay="600"><i class="far fa-function" /></tooltip>`
}


export default {
  components: {
    LoadingOverlay,
    CustomHeader,

    WeightHeader,
    LeadBid,
    NameCell,
    InputTypeCell,
    BidCell,
    ButtonCell,
    DeltaCell,
    WeightedCell
  },

  created() {
    // ----------------------------------------------------------------------
    // Load Ag-Grid Configuration
    // ----------------------------------------------------------------------

    this.defaultColDef = Object.freeze({
      sortable: false,
      resizable: true,
      suppressMovable: true,
      enableCellChangeFlash: false,
      suppressKeyboardEvent: this.checkKeypress
    });

    this.columnTypes = Object.freeze({
      moneyType,
      buttonType
    });

    this.columnDefs = Object.freeze([
      rankCol,
      numberCol,
      nameCol,
      qtyCol,

      // Bid info columns
      leadBidCol,
      reserveCol,

      // Input columns
      openingCol,
      nextCol,
      inputTypeCol,
      bidCol,
      newBidCol,
      deltaCol,
      lastDeltaCol,
      decrementCol,
      placedCol,

      // Weight Cols -- mostly hidden and used for export
      markupCol,
      percentageCol,
      adjustmentCol,
      weightedBidCol,
      formulaCol,

      // Ext Qty columns
      extQtyCol,
      extBidCol,
      extDeltaCol,

      // Optional Weighted Column
      weightedExtBidCol,

      sort
    ])
  },

  beforeMount() {
    this.gridOptions = {
      autoSizePadding: 1,
      context: this.context,
      loadingOverlayComponent: 'LoadingOverlay',
      loadingOverlayComponentParams: {
        state: () => ((this.gridApi.getDisplayedRowCount() > 0 && this.context.state === 'paused') ? 'paused' : 'loading')
      },
      rowClassRules: {
        'winning-row': (params) => (params.data.rank === 1)
      }
    };
  },

  methods: {
    processCellForExport(params) {
      const { value, column } = params;

      // Entered as percentage, not decimal
      if ((column.colId === "entered" || column.colId === "bid") && params.node.data.input_type !== 'price' && notBlank(value) && !isNaN(value)) {
        if (params.type === 'clipboard') {
          return `${value}%`;
        } else {
          return Big(value).times(0.01);
        }
      }

      // Column values as objects
      if (column.colId === "inputType") return value.inputType;
      if (["decrement", "lastDelta"].includes(column.colId)) return value.amt;

      return value;
    },

    processDataFromClipboard(params) {
      let { data } = params;
      const cell = this.gridApi.getFocusedCell(),
            headerName = cell.column.colDef.headerName;
      if (headerName === data[0][0].trim()) {
        data.shift();
      }
      return data;
    },

    processCellFromClipboard(params) {
      let { value, context } = params;

      if (typeof value === 'string' && params.column.colDef.type === "moneyType") {
        const parsed = new NumberParser(context).parse(value);
        if (!_.isNaN(parsed)) {
          // If percentage input is pasted as decimal, covert to percent
          if ((params.column.colId === 'bid' || params.column.colId === 'entered') && params.node.data.input_type !== 'price') {
            if (!/%$/.test(value.trim())) {
              return +Big(parsed).times(100); // return a number
            }
          }
          return parsed;
        }
      }

      return value;
    },

    exportBids() {
      this.exportStyles();

      const visibleCols = this.columnApi.getAllDisplayedColumns(),
            allowedCols = ['rank', 'number', 'name', 'qty', 'reserve', 'opening', 'inputType', 'entered', 'extQty'];

      const exportCols = _.filter(visibleCols, col => (allowedCols.includes(col.colId)));

      const bidState = this.context.bidState,
            weightCols = [];

      if (bidState.markup) weightCols.push('markup');
      if (bidState.percentage) weightCols.push('percentage');
      if (bidState.adjustment) weightCols.push('adjustment');

      if (weightCols.length > 0) {
        weightCols.push('weightedBid');
        const bidIndex = exportCols.findIndex(col => col.colId === 'bid');
        exportCols.splice(bidIndex+1, 0, ...weightCols);
      }

      this.gridApi.exportDataAsExcel({
        columnKeys: exportCols,
        processCellCallback: this.processCellForExport,
        fileName: `bid-export-${this.$route.params.event_id}-${this.$route.params.id}`,
        sheetName: 'Bid',
        skipPinnedBottom: true
      })
    },

    exportStyles() {
      const context = this.gridOptions.context,
            decimals = '0'.repeat(this.lot.precision);

      const safeSymbols = ['$', '€', '£']; // Ran into issues with currencies w Excel export, e.g. RSD
      const currencyFmt = (safeSymbols.includes(context.symbol)) ?
        `${context.symbol}#,##0.${decimals};-${context.symbol}#,##0.${decimals}`
        : `#,##0.${decimals};-#,##0.${decimals}`;

      this.gridOptions.excelStyles = [
        {
          id: "ag-money-cell",
          numberFormat: {format: currencyFmt}
        },
        {
          id: "ag-percent-cell",
          numberFormat: {format: `#,##0.${decimals}%;-#,##0.${decimals}%`}
        },
        {
          id: "header",
          borders: {
            borderBottom: {
              color: "#333333", lineStyle: 'Continuous', weight: 1
            },
              borderRight: {
              color: "#999999", lineStyle: 'Continuous', weight: 1
            }
          },
          font: {
            color: "#FFFFFF",
            bold: true
          },
          interior: {
            color: "#778899",
            pattern: 'Solid'
          }
        }
      ];
    },

    checkKeypress(params) {
      // Only want keypress
      if (params.event.type !== 'keydown' || params.api.getEditingCells().length > 0) return false;

      const { event, api } = params,
            key = event.which || event.keyCode || 0,
            BACKSPACE = 8,
            DELETE = 46,
            ENTER = 13,
            SPACE = 32;

      // Submit bid on Cmd/Cntrl+Enter
      if ((event.ctrlKey || event.metaKey) && key === ENTER) {
        this.$emit('submit');
        return true;
      }

      let deleting = key === BACKSPACE || key === DELETE,
          triggering = key === ENTER || key === SPACE; // Enter or Space

      // Exit if not capturing
      if (!deleting && !triggering) return false;

      const range = api.getCellRanges()[0];

      // Check if single column trigger or deleting includes bid/delta cols
      if (triggering) {
        triggering = triggering && range.columns.length === 1 && params.colDef.type === 'buttonType';
      } else {
        const colIds = range.columns.map(col => col.colId);
        deleting = deleting && (colIds.includes("bid") || colIds.includes("delta"));
      }

      if (!deleting && !triggering) return false;

      let startIndex = range.startRow.rowIndex,
          endIndex = range.endRow.rowIndex;

      if (range.endRow.rowPinned === 'bottom') {
        endIndex = api.getDisplayedRowCount() -1;
        // Trigger/delete all rows if only pinned row is selected
        if (range.startRow.rowPinned === 'bottom') startIndex = 0;
      } else if (range.startRow.rowPinned === 'bottom') {
        // If only end row is pinned, set to last index
        startIndex = api.getDisplayedRowCount() -1; // -1 as it returns pinned rows index
      }

      // NOTE: _.range second parameter is not inclusive, so add direction
      const direction = startIndex < endIndex ? 1 : -1,
            rowIndexes = _.range(startIndex, endIndex + direction, direction);

      // Let's map bulk updates for performance
      const updatedNodes = rowIndexes.map(i => {
        const node = api.getDisplayedRowAtIndex(i);

        if (triggering) {
          return updateNode(params, node, params.column.colId);
        } else if(deleting) {
          return updateNode(params, node)
        }
      })

      console.debug('Suppress Keypress:', event, updatedNodes)

      api.applyTransaction({update: updatedNodes});
      api.stopEditing(); // Don't want to open editor
      event.preventDefault();
      return true; // suppress keypress
    }
  }
}

