<template>
  <span>
    <div class="pull-right edit-bid">
      <i class="fas fa-times-circle" @click.stop="clicked"  v-if="params.value" />
      <i class="fas fa-pencil" @click.stop="edit" v-else />
    </div>

    <tooltip
      custom-class="tooltip-danger"
      placement="top"
      :enable="invalidBid"
      :text="invalidMessage"
    >
      <span class="bid" :class="{danger: error}">
        <template v-if="typeof params.valueFormatted === 'string'">
          {{ params.valueFormatted }}
        </template>


        <span class="bid-required" v-else-if="missing">Required</span>
      </span>
    </tooltip>
  </span>
</template>

<script>
  export default {
    computed: {
      error() {
        return this.isNaN || this.params.data.errors
      },
      invalidBid() {
        // Show tooltip for specific errors: ties and NaN
        const { errors } = this.params.data;
        const invalidBid = this.isNaN || (errors && (errors.ties || errors.nan));
        return invalidBid === true; // NOTE: requires boolean, was somtimes returning undefined
      },
      invalidMessage() {
        const { errors } = this.params.data
        if (this.isNaN || (errors && errors.nan)) return 'Not a Number'
        if (errors && errors.ties) return 'Tie Bid — Not Allowed'
      },
      isNaN() {
        // Only a string when value present and the setter was unable to parse
        return typeof this.params.value === 'string'
      },
      missing() {
        const { bid_required, entered } = this.params.data;
        // NOTE: is only referenced when valueFormatted is not present (and a string)
        return bid_required === true && (entered === undefined || entered === null)
      }
    },
    methods: {
      edit(e) {
        if (this.params.node.rowPinned) return false;

        this.params.api.startEditingCell({
          rowIndex: this.params.rowIndex,
          colKey: this.params.column.colId
        });
      },
      clicked(event) {
        this.params.clicked(this.params, event)
      }
    }
  }
</script>
